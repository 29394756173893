import { version } from './version';

export const environment = {
  production: false,
  ENV_NAME: 'develop',
  API_V4: 'https://dev-api.dogoodphil.com/v4',
  SHORT_URL: 'https://d.dogoodphil.com',
  ASSETS_URL: 'https://dev-assets.dogoodphil.com',
  DASH_WEB: 'https://dev-lets.dogoodphil.com/login',
  RAVEN_CONFIG:
    'https://daa9b74901dde9a11416afdc1359a524@o138343.ingest.sentry.io/4506270692343808',
  version,
  appId: 'dgp',
  ADMIN_URL: 'https://dev-we.dogoodphil.com',
};
